.ui.form .field > label {
  margin-bottom: 1.2rem;
  color: @blue;
  font-size: 1.5rem;
}

// Positions modifications page - overtime rules dropdown
.ui.form .field > .selection.dropdown {
  padding-right: 6px;

  & i {
    float: right;
    margin-right: 0;
    font-size: 0.9em;
    opacity: 0.9;
    color: rgba(0, 0, 0, 0.6);
  }
}

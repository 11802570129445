.ui.primary.button {
  color: white;
  border-radius: 2px;

  &:hover {
    color: white;
  }
}

@color-grey: #eaeaea;
@color-light-grey: lighten(@color-grey, 2%);
@color-dark-grey: #777;

.ui.button {
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 700;

  span {
    margin: 0 0 0 8px;
  }

  background-color: @color-grey;
  color: @color-dark-grey;

  &:hover,
  &:active,
  &:focus {
    background-color: @color-light-grey;
  }
}

.ui.button:not(.circular) {
  border-radius: 4px;
  padding: 1em 1.5em;
}

a.ui.button {
  padding: 1.5em 0.78571429em;
}

.ui.small.button {
  height: 3rem;
  text-transform: none;
}

.ui.tiny.button {
  &:hover,
  &:active,
  &:focus {
    background: lighten(#eaeaea, 2%) !important;
    color: #777 !important;
    box-shadow: none !important;
  }
}

@color-off-white: #fafafa;

@color-black: #777;
@color-light-black: lighten(@color-black, 5%);

.ui.black.button {
  background-color: @color-black;
  color: @color-off-white;

  &.basic {
    color: @color-black !important;

    &.toggle {
      box-shadow: 0 0 0 1px @color-black !important;
    }

    &.active {
      background: @color-black !important;
      color: @color-off-white !important;

      &:hover {
        background: @color-black !important;
        cursor: default !important;
      }
    }

    &.toggle:not(.active) {
      &:hover {
        color: @color-light-black !important;
      }
    }
  }

  &.circular.toggle {
    box-shadow: none !important;
    border: none !important;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: @color-light-black !important;
  }
}

@color-violet: #415167;
@color-light-violet: lighten(@color-violet, 5%);

.ui.violet.button {
  background-color: @color-violet;
  color: @color-off-white;

  &:hover,
  &:active,
  &:focus {
    background-color: @color-light-violet;
  }
}

.ui.yellow.button {
  background-color: @primaryColor;

  &:hover,
  &:active,
  &:focus {
    background-color: lighten(@primaryColor, 5%);
  }
}

@color-orange: #e67e22;
@color-light-orange: lighten(@color-orange, 5%);

.ui.orange.button {
  background-color: @color-orange;
  color: @color-off-white;

  &:hover,
  &:active,
  &:focus {
    background-color: @color-light-orange;
  }
}

@color-red: #e74c3c;
@color-light-red: lighten(@color-red, 5%);

.ui.red.button {
  background-color: @color-red;
  color: @color-off-white;

  &:hover,
  &:active,
  &:focus {
    background-color: @color-light-red;
  }
}

.ui.grey.button {
  background-color: @color-dark-grey;
  color: @color-off-white;

  &:hover,
  &:active,
  &:focus {
    background-color: lighten(@color-dark-grey, 5%);
  }
}

/* Modal Buttons */
.ui.button.toggle.active,
.ui.button.toggle.active:hover {
  background-color: @primaryColor !important;
}

/* Delete buttons */
.ui.basic.button,
.ui.basic.button:hover {
  box-shadow: none;
  border: none;
  font-size: 1rem;
}

/* incremental button sides */
.ui.compact.icon.button {
  height: 3.5rem;
}

/* Blue basic buttons */
.ui.basic.blue.button {
  font-size: 0.9rem;
  text-transform: uppercase;
  min-width: 15rem;
  margin: 0;
  padding: 1em 1.5em;
}

/* Add and remove buttons for overtime rules */
.ui.mini.button {
  margin: 2px;
  padding: 0.8rem;
}

/* Resetting schedule controls buttons back to semantic defaults */
.ui.button.controls_button {
  padding: @verticalPadding @verticalPadding (@verticalPadding + @shadowOffset);
}


.wage_link_button{
    text-transform: none !important;
}
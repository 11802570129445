@import '~@/less/variables';

.ui.sidebar {
  background: @black !important;
  box-shadow: none !important;
  border: none !important;
  display: flex !important;
  justify-content: space-between;
  transition: width ease-in @sidebar-transition-length !important;
  overflow: hidden;

  &::after {
    display: none;
  }

  span {
    font-size: 0.8em;
    transition: opacity ease-in @sidebar-transition-length !important;
  }

  .sol-logo-sl {
    opacity: 1;
    transition: opacity ease-in @sidebar-transition-length;
  }

  .item {
    display: flex !important;
    align-items: center;
    border-radius: 0;
    border: none !important;
    font-size: 1em;
    padding: 0.4em;

    &::before,
    &::after {
      display: none !important;
    }

    &:hover,
    &:active {
      background-color: @main-gold !important;
      color: white !important;
    }
  }

  .icon-wrap {
    width: 40px;
    height: 40px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.4em;

    svg {
      position: absolute;
    }
  }

  .reveal-icon,
  .collapse-icon {
    transition: opacity ease-in @sidebar-transition-length;
  }

  .reveal-icon {
    opacity: 0;
  }

  &.thin {
    .sol-logo-sl {
      opacity: 0;
    }

    .item {
      display: flex;
      justify-content: center;

      span {
        width: 0;
        opacity: 0;
      }
    }

    .icon-wrap {
      margin: 0;
    }

    .reveal-icon {
      opacity: 1;
    }

    .collapse-icon {
      opacity: 0;
    }
  }
}

.ui.menu.text {
  font-weight: 700;
  height: 3.5rem;
  text-transform: uppercase;
  border-bottom: none;
  position: relative;
  margin: 1em 0;

  .item {
    text-transform: uppercase;
    font-size: 1.25em;
    color: @inactive-grey;
    padding: 0.6em 1.5em;

    &.active::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      border-bottom: @main-gold 0.25rem solid;
    }
  }
}

.ui.attached.segment {
  border: none;
  padding: 1rem 0;
}

.tab_menu {
  > .ui.text.menu {
    margin-bottom: 2.5em;
    margin-top: 0;
  }

  > .ui.menu::after {
    content: '';
    position: absolute;
    bottom: -1.5em;
    width: 100%;
    border-bottom: @light-grey 1px solid;
    visibility: inherit;
  }
}

.popup_menu {
  > a {
    color: @blue-header !important;

    &::before {
      background: none !important;
    }
  }
}

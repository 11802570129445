.u_remove_margin {
  margin: 0 !important;
}

.u_margin_bottom_small {
  margin-bottom: 1.5rem !important;
}

.u_margin_bottom_medium {
  margin-bottom: 4rem !important;
}

.u_margin_bottom_big {
  margin-bottom: 8rem !important;
}

.u_margin_top_tiny {
  margin-top: 1em !important;
}

.u_margin_top_small {
  margin-top: 2rem !important;
}

.u_margin_top_medium {
  margin-top: 4rem !important;
}

.u_margin_top_big {
  margin-top: 8rem !important;
}

.u_margin_top_huge {
  margin-top: 10rem !important;
}

.flex_row_between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.remove_padding_top {
  padding-top: 0 !important;
}

.header_black {
  color: @black !important;
}

.tab_spacer {
  margin-top: calc(1em + 16px) !important;
}

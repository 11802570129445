
//Availability table
.ui.table {
  box-shadow: 1px 0 10px 4px #efefef;
}

.ui.definition.table tr td.definition,
.ui.definition.table tr td:first-child:not(.ignored) {
  background: none;
}

.ui.striped.table,
.ui.striped.table > tr:nth-child(2n) {
  tbody {
    tr:nth-child(2n) {
      background-color: #fafafa;
    }
  }
}

.top_row {
  &_container {
    padding-bottom: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &_breadcrumb {
    color: @black;
    height: 35px;
    padding: 9px 0;

    &_link>a {
      color: @black !important;
    }
  }

  &_profile_container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
@import './variables';

.fields {
  border-radius: @form-input-border-radius !important;
  box-shadow: @elevation-1 !important;
  font-size: @form-input-font-size !important;
  border: none !important;
  min-height: 32px;

  &:hover,
  &:focus {
    box-shadow: @elevation-1;
  }

  & .active {
    border: @form-input-active-border;
    outline: none;
  }
}

.circular-button {
  width: 24px;
  height: 24px;
  padding: 0 !important;
  margin: 4px 2px !important;
}

.button-bar {
  position: fixed;
  bottom: 2em;
  transition: left ease-in @sidebar-transition-length;
}

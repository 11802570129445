@import './variables';

.cell {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  cursor: pointer;

  &:not(:first-of-type) {
    margin-top: 8px !important;
  }

  &_header {
    text-transform: uppercase;
    width: 100%;
    display: inline-block;
    text-align: center;
    position: relative;
    top: calc((100% - 1.1rem) / 2);
  }

  &_bottom {
    background-color: @shift-block-highlight;
    color: @off-white !important;
    padding: 4px;
    border-radius: 0 0 4px 4px;
    text-transform: uppercase;
  }

  &_top {
    background-color: @shift-block-main;
    color: @dark-grey !important;
    padding: 4px;
    border-radius: 4px 4px 0 0;
  }
}

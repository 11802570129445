.ui.fluid.container {
  width: 100%;
  height: 100%;
}

// We're using important because Semantic-UI uses !important for this
@media only screen and (max-width: 767px) {
  .ui.container {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

@import '~@/less/variables';

@icon-height: 1em;

.ui.grid {
  margin: 0 0 0 0;
}

.ui.grid + .grid {
  margin-top: 0;
}

.ui.grid.list_layout {
  box-shadow: @elevation-1;
  overflow: hidden;

  &_header {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &_body {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  a {
    color: @blue-header;

    .column {
      color: @black;
    }
  }

  .row {
    &:nth-child(even) {
      background-color: @off-white;
    }

    .column {
      padding: 0 1rem;
    }
  }

  .list_layout_delete_column {
    position: absolute;
    right: 0;

    &_top_offset {
      top: calc(50% - (@icon-height / 2));
    }

    div {
      text-align: center;
      outline: none;
      cursor: pointer;

      svg {
        color: @dark-grey;
      }
    }
  }

  .list_layout_footer {
    width: 100%;
    padding: 0.4rem 1rem;
    display: flex;
    justify-content: flex-end;
  }

  .day_picker_grid {
    .column {
      padding: 0 !important;
      border: none !important;
    }
  }
}

.ui.grid.payroll_grid {
  margin-top: 32px;

  .column:first-of-type {
    margin-right: 8px;
  }

  .column:last-of-type {
    margin-left: 8px;
  }
}

.ui.grid > .column.list_layout_search_column {
  padding: 0;
}

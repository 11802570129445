@import '~@/less/mixins';

input:disabled,
.ui.input:disabled,
.ui.input.disabled,
input[readonly],
.ui.input.readonly,
.ui.input input[readonly],
.ui textarea[readonly],
.ui.disabled.selection {
  background-color: #ddd !important;
  cursor: not-allowed;
  opacity: 0.6;
}

.ui.input input,
.ui.form .ui.input input,
.ui.form input[type="text"] {
  line-height: 1em !important;
  .fields();
}

@import '~@/less/main';

.ui.modal > .header {
  border-bottom: 0;
  padding-bottom: 0;
  text-transform: uppercase;
  font-weight: 400;
}

.ui.modal > .header.modal_header {
  text-transform: uppercase;
  font-size: 1.3em;
  font-weight: 400;
  color: @black;
  padding: 1.2rem 1.2rem 0 1.2rem;

  &_sick-notice {
    background-color: @sick-notice-color;
    color: @off-white;
    padding: 1.2rem;
  }

  &_time-off {
    background-color: @time-off-color;
    color: @off-white;
    padding: 1.2rem;
  }

  &_swap {
    background-color: @swap-request-color;
    color: @off-white;
    padding: 1.2rem;
  }

  &_day_schedule {
    background-color: @shift-block-highlight;
    color: @off-white;
    padding: 1.2rem;
  }
}

.ui.modal > .actions {
  background: @off-white;
  border-color: #eaeaea;
  padding: 16px;
}

.ui.modal > .close {
  top: 0.8rem;
  right: 0.8rem;
}

.ui.modal.generate_schedule {
  .ui.message {
    width: calc(100% - 32px) !important;
    margin: 16px 16px 0 16px !important;
  }

  .ui.message + .header {
    padding-top: 16px !important;
  }
}

.wage_modal_container{  
  .header.modal_header {
    font-weight: 600 !important;
    font-size: 20px !important;
    padding-left: 24px !important;
     text-transform: none !important;
  }
}
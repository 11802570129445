.date_picker {
  border-radius: @form-input-border-radius !important;
  box-shadow: @elevation-1 !important;

  button {
    margin: 0 !important;
  }
}

:global #requests_start,
:global #requests_end,
:global #date_of_birth {
  height: 32px;
}

:global #requests_start,
:global #requests_end {
  border-radius: 0 !important;
  box-shadow: none !important;
}


#calendar_id{
  input {
    box-shadow: none !important;
    text-align: center;
  }
}
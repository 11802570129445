/*******************************
        User Overrides
*******************************/
@import '~@/less/mixins';

.ui.selection.dropdown {
  display: flex;
  justify-content: space-between;
  padding: 11px 1em;
  .fields();

  &.active,
  &.visible {
    .fields();

    &:hover .menu,
    .menu,
    .menu:hover {
      border-color: rgba(34, 36, 38, 0.15);
    }
  }

  & > .clear {
    z-index: 2;
  }
}

.ui.multiple.selection.dropdown {
  display: block;
  padding: @multipleSelectionPadding;
  padding-right: 1em;

  & > .text {
    margin: 0;
    padding-top: 6px;
  }

  & > .icon {
    padding-top: 6px;
    float: right;
  }

  & > .label {
    background-color: @primaryColor;
    color: @white;
    font-weight: 400;
    border-radius: 16px;

    & > i {
      color: @white;
      line-height: 1.2em;
    }
  }
}

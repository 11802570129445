@import './breakpoints';
@import './base/utilities';

.body {
  &_menus {
    border: none;

    &_item_color {
      color: #000 !important;
    }
  }
}

.page {
  &_header {
    font-size: 2rem;
    text-transform: capitalize;
  }
}

.alternating_rows *:nth-child(even) {
  background: #fafafa;
}

.form {
  &_headers {
    margin-bottom: 0.5rem;
    color: @black;
    font-size: 1.1rem;
    font-weight: 700;
  }

  &_subheaders {
    margin-bottom: 5px;
    color: @color-blue;
    margin-top: 0;
    font-weight: 400;
  }

  &_inputs {
    width: 100%;
    height: 3rem;
  }

  &_uppercase {
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
}

.modal {
  &_headers {
    font-size: 1rem;
  }

  &_divider {
    margin-left: 3% !important;
    margin-right: 3% !important;
  }

  &_header {
    margin-bottom: 1rem;
    width: 100%;
  }
}

.confirm {
  &_wrapper > button:last-of-type {
    margin: 0 !important;
  }

  &_button {
    width: 12rem;
    margin-right: 16px !important;
  }
}

.flex_group {
  display: flex;
  align-items: center;
  max-width: 75%;
}

.flex_group_field {
  flex: 1 !important;
}

.checkboxes {
  display: inline-block;
  padding-right: 2rem;
  padding-top: 1rem;
  margin-bottom: 1rem !important;
}

.checkbox {
  &_subheader {
    font-weight: 200;
    color: @color-blue;
    width: 100%;
    margin-bottom: 0;
  }
}

.search_bar {
  width: 50%;
}

.search_bar > input {
  width: 70%;
  display: inline-block;
  height: 4rem;
  border: none !important;
  border-color: transparent;
  font-size: 1.6rem;

  &::placeholder {
    font-size: 1.6rem;
  }
}

.add_button {
  float: right;
  font-size: 1rem;
  border-radius: 2px;
  margin-left: 2rem;
  margin-right: 0.7rem;
  display: flex;

  @media @small-laptop {
    margin-right: 1.6rem;
  }
}

.search_hr {
  width: 85%;

  @media @laptop {
    width: 80%;
  }

  @media @small-laptop, @tablet {
    width: 70%;
  }
}

.search_icon {
  margin-right: 7px;
  font-size: 1.5 rem;
  color: #cccbca;
}

.button_icon {
  margin-right: 7px;
}

// Reusable Tables
.table {
  &_row {
    background-color: #fafafa;
    font-size: 1.4rem;
    margin: 16px 0;
    display: table-row;
    border-bottom: solid white 10px;
    padding: 8px;
  }

  &_icon {
    float: right;
    cursor: pointer;
    font-size: 1.3rem;

    &:hover {
      color: #212f43;
    }
  }

  &_headers_gold {
    margin-bottom: 1rem;
    color: @color-primary;
    font-size: 1.5rem;
    font-weight: 700;
  }

  &_header {
    background-color: @color-grey-dark;
    color: #fff;
  }

  &_subheader {
    background-color: @color-blue;
    color: #fff;
    text-transform: uppercase;
  }

  // Dropdowns on Schedule Details page
  &_dropdowns {
    font-size: 1.1rem !important;
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search_wrapper {
  display: flex;
  align-items: center;
  flex: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 32px 0 0;

  .input {
    flex: 1;
  }

  input {
    border: none !important;
    border-color: transparent;
    font-size: 1.6rem;
  }
}

.error_class,
.error_message {
  color: #ee5253 !important;
  margin: 8px 0 0 0 !important;
}

.error_message {
  margin: 16px 0 !important;
  font-size: 1.1em;
}

// Error message on shift planning page for day picker
.error_day_picker {
  margin-top: 4px !important;
}

// Error message on shift planning page for day picker
.error_time_picker {
  margin-top: 12px !important;
}

// Component loading error message
.error_message_icon {
  font-size: 1.8em;
  margin-right: 16px;
}

// Flex wrappers
.flex_wrapper {
  display: flex;
}

.flex_between {
  display: flex;
  justify-content: space-between;
}

.flex_center {
  display: flex;
  justify-content: center;
}

.border_right {
  border-right: 1px solid rgba(191, 191, 191, 0.87);
}

.icon_margin {
  margin-right: 15px;
}

.chevrons {
  cursor: pointer;
}

// Table cell and position highlighting

@colors: @color-green, @color-blue, orange, red;

.style(@list, @i: 1) when (@i <= length(@list)) {
  @color: extract(@colors, @i);

  .position {
    &_cell {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 5px;
      text-transform: uppercase;
      font-size: 10px;

      &:focus {
        outline: none;
      }
    }

    &_shift_@{i} {
      border: 1px solid @color;

      &_request {
        color: #fff;
        background-color: @color;
      }

      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &_highlight_@{i} {
      color: @color;
      font-weight: bold;
    }
  }

  .style(@list, @i + 1);
}

.style(@colors);

.set_height {
  height: 42px;
  cursor: pointer;
}

.search_input {
  max-height: 30px;
  border: #d4d4d5 2px solid;
  border-radius: 5px;

  & > input {
    border: none !important;
  }
}

.requests_status {
  text-transform: capitalize;

  &_denied {
    color: @red-negative !important;
  }
}

.popup_menu {
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;

  div,
  a {
    color: @blue-header !important;
    border: none !important;
    box-shadow: none !important;
    text-align: center !important;
    font-size: 0.9rem;

    &::before {
      background: none !important;
    }

    &:hover {
      background: lighten(@color-grey-lightest, 6%) !important;
      cursor: pointer;
    }

    &:first-of-type {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-of-type {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.dropdowns {
  min-height: 32px !important;
}

.clickable {
  cursor: pointer;
}

.radio {
  margin: 0 0 0 8px;
  font-size: 1.1em !important;
  z-index: 0;
}

.wage-container{
  .ui.breadcrumb{
    .active.section{
      font-weight: 600 !important;
      color: #616161 !important;
    }
  }
  .list_layout_header{
    .row{
      font-weight: 400 !important;
      font-size: 14px !important;
    }

  }
  .list_layout_body{
    .row{
      div{
        text-align: center;
      }
      div:first-child {
        text-align: left;
      }
    }
    .row:nth-child(even) {
      background-color: #F5F6F9 !important; 
      font-weight: 400;
      font-size: 14px;
    }
  }
}